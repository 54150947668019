import { format } from 'date-fns';

/* eslint-disable no-param-reassign */
export function uniqueArray(array) {
  return array.filter((value, index, self) => self.indexOf(value) === index);
}

export function removeTrailingSlash(site) {
  return site.replace(/\/$/, '');
}

export function toCamelCase(str) {
  return str
    .replace(/\s(.)/g, (a) => a.toUpperCase())
    .replace(/\s/g, '')
    .replace(/^(.)/, (b) => b.toLowerCase());
}

export function isValidDate(d) {
  return d instanceof Date && !Number.isNaN(d);
}

export const partition = (array = [], isValid) =>
  // Use the reduce method to iterate over each element of the input array.
  array.reduce(
    // For each element, check if it satisfies the isValid condition.
    // If it does, add it to the pass array; otherwise, add it to the fail array.
    ([pass, fail], elem) =>
      isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]],
    // Start with an initial value of two empty arrays: pass and fail.
    [[], []]
  );

export const insertURLParam = (key, value) => {
  key = encodeURIComponent(key);
  value = encodeURIComponent(value);

  // kvp looks like ['key1=value1', 'key2=value2', ...]
  const kvp = document.location.search.substr(1).split('&');
  let i = 0;

  // eslint-disable-next-line no-plusplus
  for (; i < kvp.length; i++) {
    if (kvp[i].startsWith(`${key}=`)) {
      const pair = kvp[i].split('=');
      pair[1] = value;
      kvp[i] = pair.join('=');
      break;
    }
  }

  if (i >= kvp.length) {
    kvp[kvp.length] = [key, value].join('=');
  }

  // can return this or...
  const params = kvp.join('&');

  // reload page with new params
  document.location.search = params;
};

/**
 * Google analytics function
 */

export const pushStepToDataLayer = ({ key, ...rest }) => {
  window.dataLayer?.push({
    event: key,
    ...rest,
  });
};

export const pushViewItemToDataLayer = (
  summary,
  total,
  currencyCode,
  couponCode
) => {
  const { total: totalPrice, tax: taxPrice, discount, totalMonthly } = total;
  const toNumber = (number) => number / 100;

  const viewItemData = {
    event: 'view_item',
    ecommerce: {
      value: toNumber(totalPrice),
      tax: taxPrice,
      currency: currencyCode,
      coupon: couponCode,
      discount: toNumber(discount),
      monthlyPrice: toNumber(totalMonthly),
      items: summary.flatMap(
        ({ summary: { plans: sumPlans, discounts }, plans: planItems }) => {
          const itemDiscount = discounts ? discounts[0].discount : null;

          return sumPlans
            .filter(({ is_addon }) => !is_addon)
            .map((sumPlan) => {
              const {
                uid: productId,
                name: productName,
                price: productPrice,
                registration: productRegistration,
                is_addon: isAtav,
              } = sumPlan;

              const hasAddon = planItems.find(({ is_addon }) => is_addon);

              return {
                item_id: planItems.find(({ uid }) => uid === productId)?.plan,
                item_name: productName,
                ...(!isAtav && {
                  registration: toNumber(productRegistration) || null,
                }),
                price: toNumber(productPrice),
                quantity: 1,
                ...(hasAddon && {
                  atav: hasAddon,
                }),
                ...(itemDiscount && {
                  discount: {
                    amount: toNumber(itemDiscount.amount),
                    percentage: itemDiscount.percentage,
                    name: itemDiscount.name,
                    registrationFee: itemDiscount.registrationFee,
                  },
                }),
              };
            });
        }
      ),
    },
  };

  window.dataLayer?.push(viewItemData);
};

export const pushAddToCartToDataLayer = (
  memberships,
  checkoutPrice,
  currencyCode,
  couponCode
) => {
  const {
    total: totalPrice,
    tax: taxPrice,
    discount,
    totalMonthly,
  } = checkoutPrice;
  const toNumber = (number) => number / 100;

  const addToCartData = {
    event: 'add_to_cart',
    ecommerce: {
      value: toNumber(totalPrice),
      tax: taxPrice,
      currency: currencyCode,
      coupon: couponCode,
      discount: toNumber(discount),
      monthlyPrice: toNumber(totalMonthly),
      items: memberships.flatMap(
        ({ summary: { plans: sumPlans, discounts }, plans: plansId }) => {
          const itemDiscount = discounts ? discounts[0].discount : null;

          return sumPlans
            .filter(({ is_addon }) => !is_addon)
            .map((sumPlan) => {
              const {
                uid: productId,
                name: productName,
                price: productPrice,
                registration: productRegistration,
                is_addon: isAtav,
              } = sumPlan;

              const hasAddon = plansId.find(({ is_addon }) => is_addon);

              return {
                item_id: plansId.find(({ uid }) => uid === productId)?.plan,
                item_name: productName,
                ...(!isAtav && {
                  registration: toNumber(productRegistration) || null,
                }),
                price: toNumber(productPrice),
                quantity: 1,
                ...(hasAddon && {
                  atav: hasAddon,
                }),
                ...(itemDiscount && {
                  discount: {
                    amount: toNumber(itemDiscount.amount),
                    percentage: itemDiscount.percentage,
                    name: itemDiscount.name,
                    registrationFee: itemDiscount.registrationFee,
                  },
                }),
              };
            });
        }
      ),
    },
  };

  window.dataLayer?.push(addToCartData);
};

export const pushBeginCheckoutToDataLayer = (
  newUser,
  memberships,
  checkoutPrice,
  currencyCode,
  couponCode
) => {
  const {
    total: totalPrice,
    tax: taxPrice,
    discount,
    totalMonthly,
  } = checkoutPrice;
  const toNumber = (number) => number / 100;

  const beginCheckoutData = {
    event: 'begin_checkout',
    ecommerce: {
      newUser,
      value: toNumber(totalPrice),
      tax: taxPrice,
      currency: currencyCode,
      coupon: couponCode,
      discount: toNumber(discount),
      monthlyPrice: toNumber(totalMonthly),
      items: memberships.flatMap(
        ({ summary: { plans: sumPlans, discounts }, plans: plansId }) => {
          const itemDiscount = discounts ? discounts[0].discount : null;

          return sumPlans
            .filter(({ is_addon }) => !is_addon)
            .map((sumPlan) => {
              const {
                uid: productId,
                name: productName,
                price: productPrice,
                registration: productRegistration,
                is_addon: isAtav,
              } = sumPlan;

              const hasAddon = plansId.find(({ is_addon }) => is_addon);

              return {
                item_id: plansId.find(({ uid }) => uid === productId)?.plan,
                item_name: productName,
                ...(!isAtav && {
                  registration: toNumber(productRegistration) || null,
                }),
                price: toNumber(productPrice),
                quantity: 1,
                ...(hasAddon && {
                  atav: hasAddon,
                }),
                ...(itemDiscount && {
                  discount: {
                    amount: toNumber(itemDiscount.amount),
                    percentage: itemDiscount.percentage,
                    name: itemDiscount.name,
                    registrationFee: itemDiscount.registrationFee,
                  },
                }),
              };
            });
        }
      ),
    },
  };

  window.dataLayer?.push(beginCheckoutData);
};

export const pushAddPaymentInfoToDataLayer = (
  memberships,
  checkoutPrice,
  currencyCode,
  couponCode
) => {
  const {
    total: totalPrice,
    tax: taxPrice,
    discount,
    totalMonthly,
  } = checkoutPrice;
  const toNumber = (number) => number / 100;

  const addPaymentInfoData = {
    event: 'add_payment_info',
    ecommerce: {
      value: toNumber(totalPrice),
      tax: taxPrice,
      currency: currencyCode,
      coupon: couponCode,
      discount: toNumber(discount),
      monthlyPrice: toNumber(totalMonthly),
      items: memberships.flatMap(
        ({ summary: { plans: sumPlans, discounts }, plans: plansId }) => {
          const itemDiscount = discounts ? discounts[0].discount : null;

          return sumPlans
            .filter(({ is_addon }) => !is_addon)
            .map((sumPlan) => {
              const {
                uid: productId,
                name: productName,
                price: productPrice,
                registration: productRegistration,
                is_addon: isAtav,
              } = sumPlan;

              const hasAddon = plansId.find(({ is_addon }) => is_addon);

              return {
                item_id: plansId.find(({ uid }) => uid === productId)?.plan,
                item_name: productName,
                ...(!isAtav && {
                  registration: toNumber(productRegistration) || null,
                }),
                price: toNumber(productPrice),
                quantity: 1,
                ...(hasAddon && {
                  atav: hasAddon,
                }),
                ...(itemDiscount && {
                  discount: {
                    amount: toNumber(itemDiscount.amount),
                    percentage: itemDiscount.percentage,
                    name: itemDiscount.name,
                    registrationFee: itemDiscount.registrationFee,
                  },
                }),
              };
            });
        }
      ),
    },
  };

  window.dataLayer?.push(addPaymentInfoData);
};

export const convertUTCDateToLocalDate = (date) => {
  const newDate = new Date(
    date.getTime() + date.getTimezoneOffset() * 60 * 1000
  );
  const offset = date.getTimezoneOffset() / 60;
  const hours = date.getHours();
  newDate.setHours(hours - offset);
  return newDate;
};

export const formatTime = (time) => {
  const zeroPad = (num, places) => String(num).padStart(places, '0');

  const secs = Math.floor(time) % 60;
  const mins = Math.floor(time / 60) % 60;
  return `${zeroPad(mins, 2)}:${zeroPad(secs, 2)}`;
};

export const periodHandler = ({ period, formatDate, parse }) => {
  if (!period) return '';
  if (typeof period !== 'string') {
    return `${formatDate(period.start, 'dd.MM.yyyy')} - ${formatDate(
      period.end,
      'dd.MM.yyyy'
    )}`;
  }
  const dateArr = period.split(' - ');
  const start = formatDate(
    parse(dateArr[0], 'dd-MM-yyyy', new Date()),
    'dd.MM.yyyy'
  );
  const end = formatDate(
    parse(dateArr[1], 'dd-MM-yyyy', new Date()),
    'dd.MM.yyyy'
  );
  return `${start} - ${end}`;
};

export const phonePrefix = (locale) => {
  switch (locale) {
    case 'se':
      return '+46';
    case 'no':
      return '+47';
    case 'de':
      return '+49';
    default:
      return '+45';
  }
};

export const formatStartDate = (date) => {
  return format(isValidDate(date) ? new Date(date) : new Date(), 'yyyy-MM-dd');
};

export const pushPurchaseToDataLayer = (
  orderId,
  isUserNew,
  memberships,
  checkoutPrice,
  currencyCode,
  couponCode,
  sessionId
) => {
  const {
    total: totalPrice,
    vat: taxPrice,
    discount,
    nextMonthsPayment,
  } = checkoutPrice;
  const discountAmount = discount ? discount.amount : 0;
  const toNumber = (number) => number / 100;

  // Initialize the items array
  const items = [];

  // Iterate over the keys of the memberships object
  for (const key in memberships) {
    if (memberships.hasOwnProperty(key)) {
      const itemArray = memberships[key];

      let products = {};
      let registration = {};
      let discount = {};
      let addon = {};
      // Process each item in the array
      itemArray.forEach((item) => {
        if (item.type === 'product') {
          products = {
            name: item.name,
            item_id: item.uid,
            price: toNumber(item.amount),
            quantity: 1, // Hardcoded to 1 for now
          };
        }
        if (item.type === 'registration') {
          registration = {
            registration: toNumber(item.amount),
          };
        }
        if (item.type === 'discount') {
          discount = {
            discount: toNumber(item.amount),
          };
        }
        if (item.type === 'addon') {
          addon = {
            atav: toNumber(item.amount),
          };
        }
      });
      // Merge all the items into a single object
      const finalizedMembership = {
        ...products,
        ...registration,
        ...discount,
        ...addon,
      };

      items.push(finalizedMembership);
    }
  }

  const purchaseData = {
    event: 'purchase',
    session_id: sessionId,
    ecommerce: {
      newUser: isUserNew,
      transaction_id: orderId,
      value: toNumber(totalPrice),
      tax: taxPrice,
      currency: currencyCode,
      coupon: couponCode,
      discount: toNumber(discountAmount),
      totalMonthly: toNumber(nextMonthsPayment),
      items: items,
    },
  };

  window.dataLayer?.push(purchaseData);
};

export const pushCampaignToDataLayer = (
  campaignName,
  itemId,
  value,
  currency,
  userType
) => {
  const campaignData = {
    event: 'campaignSignup',
    campaignName: campaignName,
    item_id: itemId,
    value: value,
    currency: currency,
    userType: userType,
  };
  window.dataLayer?.push(campaignData);
};

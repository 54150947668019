import Link from '@components/Link';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import linkShape from '@features/WordPress/utilities/prop-types/shapes/link';
import {
  List,
  MenuOpen,
  MenuItem,
  MenuLink,
  MenuLinkActive,
  MenuLinkHover,
} from './Navigation.module.css';

const Navigation = ({ className, items, onClick, menuIsOpen }) => {
  const classes = classnames(
    List,
    {
      [MenuOpen]: menuIsOpen,
    },
    className
  );

  return (
    <ul className={classes}>
      {items &&
        items.map(({ link: { url, title } = {} }) => (
          <li key={JSON.stringify(title, url)} className={MenuItem}>
            <Link
              className={MenuLink}
              activeClassName={MenuLinkActive}
              hoverClassName={MenuLinkHover}
              href={url}
              onClick={onClick}
              external={true}
            >
              {title}
            </Link>
          </li>
        ))}
    </ul>
  );
};

Navigation.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: linkShape,
    })
  ).isRequired,
};

export default Navigation;
